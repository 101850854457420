<template>
<div id="app">
<keep-alive>
<router-view />
</keep-alive>
</div>
</template>

<script> 
export default {
  name: 'app',
  metaInfo() {
      return {
          title: this.$store.getters.config ? this.$store.getters.config.siteName : '',
      }
  }
}
</script>
<style>
	 .loading{
	 	display: flex;
	 	justify-content: center;
	 	padding: 10rem;
	 }
 </style>