<template>
	<footer class="bounds">
		<div class="topLine" v-if="showtop"></div>
		<div class="foter">
			<div class="logobox">
				<img class="logo" :src="detail.realFootLogo" />
			</div>
			<div class="flex-between menus">
				<div>
					<div class="sitemap flex-lr tec">
						<ul v-for="item in menuList">
							<li><h3 class="b"><a target="_blank" :href="itemUrl(item)">{{item.menuName}}</a></h3></li>
							<li v-for="sit in item.children">
								<a target="_blank" :href="$pageUrl(sit)">{{sit.menuName}}</a></li>
						</ul>
					</div>
					

				</div>
				<div class="qrcode tec">
					<h3 class="b">关注我们</h3>
					<img :src="detail.realQr" />
				</div>
			</div>
			<div class="flex-between siteinfo">
				<div class="adres">联系电话：{{detail.phone}}<br>联系地址：{{detail.address}}</div>
				<div class="others">
					<label>{{links1.name}}：</label>
					
					<div class="mr20 selectBox" @click.stop="show1=true">
						<p>请选择</p>
						<ul :class="{'show':show1}">
							<li @click.stop="changeAction(item.siteLink)" v-for="item in links1.list">{{item.siteName}}</li>
						</ul>
					</div>
					
					
					<label>{{links2.name}}：</label>
					
					<div class="mr20 selectBox" @click.stop="show2=true">
						<p>请选择</p>
						<ul :class="{'show':show2}">
							<li @click.stop="changeAction(item.siteLink)" v-for="item in links2.list">{{item.siteName}}</li>
						</ul>
					</div>
					
				</div>
			</div>



			<div class="copyright">
				<div class="copy">
					<span>版权所有<a target="_blank" href="https://beian.miit.gov.cn/">{{detail.icp}}</a></span>
					<div>{{detail.siteName}}</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		created() {
			let self = this
			this.axios.all([this.axios.get(`dict/type/site_friend_type/2`), this.axios.get(`siteFriendSiteLink/list/2`)])
				.then(res => {
					let res1 = res[0]
					let res2 = res[1]
					if (res1 && res2) {
						self.links1.name = res1.data
						self.links1.list = res2.data
					}

				}).catch(error => {});
			this.axios.all([this.axios.get(`dict/type/site_friend_type/3`), this.axios.get(`siteFriendSiteLink/list/3`)])
				.then(res => {
					let res1 = res[0]
					let res2 = res[1]
					if (res1 && res2) {
						self.links2.name = res1.data
						self.links2.list = res2.data
					}
				}).catch(error => {});
		},
		computed: {
			detail() {
				return this.$store.getters.config
			},
			menuList() {
				return this.$store.getters.menus
			}
		},
		props: {
			showtop: {
				type: Boolean,
				default: true
			}
		},
		methods: {
			changeAction(e) { 
				this.show1 = false
				this.show2 = false
				window.open(e)
			},
			itemUrl(item) {
				if (item.children && item.children.length) {
					item = item.children[0]
				}
				return this.$pageUrl(item.menuType, item.menuId, item.menuId)
			}
		},
		mounted() { 
			document.body.addEventListener('click',(e)=>{
				this.show1 = false
				this.show2 = false
			})
		},
		data() {
			return {
				show1:false,
				show2:false,
				links1: {
					name: '',
					list: [],
				},
				links2: {
					name: '',
					list: []
				}
			}
		}
	}
</script>

<style scoped>
	footer {
		padding: 2.64rem 0 2rem;
	}

	.topLine {
		height: 1px;
		font-size: 0;
		overflow: hidden;
		background-color: #ddd;
	}

	.foter {
		padding-top: 1.84rem;
	}

	.siteinfo {
		border-bottom: #BD8E75 solid 1px;
		padding-bottom: 1.8rem;
	}

	.bounds .logo {
		width: 26rem;
		height: 5.5rem;
	}


	.menus {
		padding: 1.2rem 0 6rem;
		align-items: flex-end;
		min-height: 9.375rem;
	}

	.sitemap {
		width: 48rem;
		font-size: 0.86rem;
		line-height: 2rem;
	}

	.sitemap li {
		padding-right: 2rem;
		font-size: .8rem;
		line-height: 1.6rem;
	}

	.sitemap a {
		color: #333;
	}


	.qrcode img {
		width: 7.86rem;
		height: 7.86rem;
	}

	h3 {
		line-height: 3rem;
		font-size: 1.13rem; 
	}

	.copyright {
		padding: 3rem 0;
	}

	.copy {
		font-size: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.vlcenter {
		align-items: center;
	}
	
	.others{
		display:flex;
		justify-content: end;
		align-items: center;
	}
	.others label {
		padding-right: 0.5rem;
	}
	
	.others .selectBox{
		width: 15.86rem;
		height: 2.29rem;
		padding: 0 .8rem;
		line-height: 2.29rem;;
		border: #ddd solid 1px;
		border-radius: 2px;
		position: relative;
	}
	.others .selectBox p{
		color: #999;
	}
	.others .selectBox li{
		word-break: keep-all;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 0.625rem;
		box-sizing: border-box;
	}
	.others .selectBox ul.show{
		display: block;
	}
	.others .selectBox ul{
		position: absolute;
		bottom: 2.29rem;
		background-color: #fff;
		border: #ddd solid 1px;
		width: 100%;
		left:0;
		display: none;
		
	}

	.mr20 {
		margin-right: 4rem;
	}

	@media screen and (max-width: 750px) {
		footer {
			background: #EFEFEF;
			padding: 0;
		}

		.bounds .foter {
			border: none;
		}

		.copyright .copy {
			flex-direction: column;
			padding-top: 3rem;
			width: 100%;
			text-align: center;
			line-height: 2rem;
		}

		.copyright .copy a {
			text-decoration: underline;
		}

		.bounds .others,
		.bounds .logobox,
		.bounds .siteinfo,
		.bounds .foter .menus {
			display: none;
		}
	}
</style>